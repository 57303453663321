'use client';
import { HowToDownload } from './HowToDownload';
import { H2, H3, H4, P } from './ui/Typography';
import {
  Accordion,
  AccordionItem,
  AccordionContent,
  AccordionTrigger,
} from './ui/Accordion';
import Link from 'next/link';

export function Faq() {
  return (
    <section className='my-20' id='faq'>
      <H2 className='text-center'>Frequently Asked Questions</H2>

      <Accordion type='single' collapsible defaultValue='about'>
        <AccordionItem value='about' id='about'>
          <AccordionTrigger className='font-bold hover:no-underline'>
            What is Freepik Downloader?
          </AccordionTrigger>
          <AccordionContent className='bg-secondary'>
            <P className=''>
              Freepik Downloader Freepik is an online service that allows you to
              save unlimited Premium stock vectors/photo content on your
              Computer (PC/Mac) or mobile phone without a watermark. You don’t
              have to pay for anything for our service. Just paste your
              <strong>image URL Link</strong> in the Input Box Given Above and
              click <strong>&apos;Download&apos;</strong>, and that&apos;s it.
              This tool is the fastest and easiest way to download Freepik
              Premium Images and Vector for free.
            </P>
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value='how-to-download' id='how'>
          <AccordionTrigger className='font-bold hover:no-underline'>
            How to Download Freepik Images, Vector, and others?
          </AccordionTrigger>
          <AccordionContent className='bg-secondary'>
            <HowToDownload />
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value='payment'>
          <AccordionTrigger className='font-bold hover:no-underline'>
            {' '}
            Do I have to pay to get Premium Stock Vector/Photos (Images)?
          </AccordionTrigger>
          <AccordionContent className='bg-secondary'>
            <P>
              {' '}
              No, everything on Freepik Downloader is free for our users,
              meaning you don’t have to pay.
            </P>
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value='quality'>
          <AccordionTrigger className='font-bold hover:no-underline'>
            Are the Vector, Images/Photos quality reduced?{' '}
          </AccordionTrigger>
          <AccordionContent className='bg-secondary'>
            <P>
              No, our technology is able to get premium images without reducing
              its quality, you get what ordered
            </P>
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value='save-location'>
          <AccordionTrigger className='font-bold hover:no-underline'>
            Where are my files saved?{' '}
          </AccordionTrigger>
          <AccordionContent className='bg-secondary'>
            <P>
              Your Freepik Vector, Photo & PSD are usually saved under
              “Downloads” folder but you may use Save As Option and changed it
              to another one. If you can’t find your file afterndownload, you
              can always check your download history on your browser.
            </P>
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value='android'>
          <AccordionTrigger className='font-bold hover:no-underline'>
            How To use the Freepik Downloader on Android?{' '}
          </AccordionTrigger>
          <AccordionContent className='bg-secondary'>
            <P>To use the Freepik Downloader on Android, follow these steps.</P>
            <ol className='my-6 ml-6 list-disc [&>li]:mt-2'>
              <li>
                <P>
                  {' '}
                  Step 1: Copy the image URL and paste it into the downloader
                  input box.
                </P>
              </li>
              <li>
                <P>
                  {' '}
                  Step 2: Click on the &quot;Download&quot; button. That&apos;s
                  it.
                </P>
              </li>
            </ol>
          </AccordionContent>
        </AccordionItem>

        <AccordionItem value='safety'>
          <AccordionTrigger className='font-bold hover:no-underline'>
            Is Freepik Downloader safe for my device?
          </AccordionTrigger>
          <AccordionContent className='bg-secondary'>
            <P>
              {' '}
              Yes, Freepik Downloader is a secured platform. We prioritize user
              privacy and security, and we do not store any personal information{' '}
              <Link href='privacy-policy' className='underline'>
                learn more
              </Link>
              . Our website is regularly updated to ensure it remains free from
              viruses and malware.
            </P>
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value='limit'>
          <AccordionTrigger className='font-bold hover:no-underline'>
            Is there a limit to the number of images I can download?{' '}
          </AccordionTrigger>
          <AccordionContent className='bg-secondary'>
            <P>
              {' '}
              No, there is no limit to the number of images you can download
              with our free online tool. You can download unlimited images here.
            </P>
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value='authentication'>
          <AccordionTrigger className='font-bold hover:no-underline'>
            Do I need to sign up to use this Freepik Downloader tool?{' '}
          </AccordionTrigger>
          <AccordionContent className='bg-secondary'>
            <P>
              No, there is no need to sign up or give any of your details to use
              this tool. It&apos;s only here to make it easier for you to
              seamlessly download Freepik images unlimitedly for free.
            </P>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </section>
  );
}
