interface ButtonProps {
  isOpen: boolean;
  onToggle: () => void;
}

export function NavbarToggleButton({ isOpen, onToggle }: ButtonProps) {
  return (
    <button
      title='Toggle Navigation Menu'
      onClick={onToggle}
      className={`text-5xl focus:outline-none border-none z-[53] h-6 md:hidden block relative ${
        isOpen ? '' : 'gap-1'
      }`}
    >
      <span
        className={`block h-1 w-7  before:w-full before:bg-accent before:h-full before:block 
        after:w-full after:bg-accent after:h-full after:block duration-300 after:duration-300 before:duration-300
        ${
          isOpen
            ? 'bg-transparent before:translate-y-0 after:-translate-y-[4px] before:rotate-45 after:-rotate-45'
            : 'bg-accent before:-translate-y-[6px] after:translate-y-[2px] before:rotate-0 after:rotate-0'
        }
        `}
      ></span>
    </button>
  );
}
