"use client";

import { useEffect, useId } from "react";
import { usePathname, useSearchParams } from "next/navigation";

type AdBannerProps = {
  style?: any;
};
export default function AdBanner({ style, ...props }: AdBannerProps) {
  const id = useId();

  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(
    function () {
      function fetchAd() {
        try {
          (window.adsbygoogle = window.adsbygoogle || []).push({});
        } catch (err) {
          console.log(err);
        }
      }
      fetchAd();
    },
    [pathname, searchParams]
  );

  return (
    <ins
      key={id}
      id={id}
      className="adsbygoogle"
      style={{ display: "block", overflow: "hidden", ...style }}
      data-ad-client="ca-pub-3915509304569489"
      {...props}
    ></ins>
  );
}
