import Link, { LinkProps } from 'next/link';

interface NavLinkProps extends LinkProps {
  children: React.ReactNode;
  target?: '_blank' | '_parent' | '_self' | '_top';
}

export function NavLink({ children, ...props }: NavLinkProps) {
  return (
    <Link
      {...props}
      className='py-2 px-4 block after:bg-secondary after:block after:w-0 hover:after:w-full hover:after:ml-0 after:h-[0.5px] hover:after:bg-secondary hover:after:text-primary-foreground hover:after:rounded-md hover:after:overflow-hidden hover:after:whitespace-nowrap hover:after:font-semibold hover:after:content-[attr(title)] hover:after:transition-all hover:after:duration-300 hover:after:ease-in-out'
    >
      {children}
    </Link>
  );
}
