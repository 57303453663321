import Image from "next/image";

export function HowToDownload() {
  return (
    <div id='#how' className='grid gap-10 pt-5'>
      <figure>
        <figcaption className='text-2xl font-bold mb-5'>
          1- Right click on the freepik image
        </figcaption>
        <Image
          src='/select premium image.png'
          alt='Screenshot on how to download'
          height={700}
          width={1400}
          className='md:w-3/4 mx-auto'
        />
      </figure>
      <figure>
        <figcaption className='text-2xl font-bold mb-5'>
          2- Copy image address
        </figcaption>
        <Image
          src='/copy the image address.jpg'
          alt='Screenshot on how to download'
          height={700}
          width={1400}
          className='md:w-3/4 mx-auto'
        />
      </figure>
    </div>
  );
}
