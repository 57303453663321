import useWindowSize from "../../hooks/useWindowSize";
import { NavLink } from "./NavLink";

export function PolicyNavLinkList({ isOpen }: { isOpen: boolean }) {
  return (
    <>
      <div className='hidden md:flex md:flex-row gap-3'>
        <NavLink href='/terms-of-service'>Terms</NavLink>
        <NavLink href='/privacy-policy'>Privacy Policy</NavLink>
      </div>
      <div
        className={`block md:hidden absolute top-0 left-0 right-0 z-50 w-full  bg-primary text-primary-foreground transition-all overflow-hidden ${
          isOpen ? 'h-[100dvh]' : 'h-0'
        }`}
      >
        <div className='text-2xl font-medium mt-20 flex flex-col flex-nowrap gap-2 h-full transition'>
          <NavLink href='/terms-of-service'>Terms</NavLink>
          <NavLink href='/privacy-policy'>Privacy Policy</NavLink>
        </div>
      </div>
    </>
  );
}
