import { cn } from '@/lib/utils';

type TypographyProps = {
  children: React.ReactNode;
  className?: string;
};

export function H1({ children, className, ...props }: TypographyProps) {
  return (
    <h1
      {...props}
      className={cn(
        'scroll-m-20 text-5xl font-extrabold tracking-tight lg:text-6xl',
        className,
      )}
    >
      {children}
    </h1>
  );
}

export function H2({ children, className, ...props }: TypographyProps) {
  return (
    <h2
      {...props}
      className={cn(
        'scroll-m-20 pb-2 text-3xl sm:text-4xl font-semibold tracking-tight first:mt-0',
        className,
      )}
    >
      {children}{' '}
    </h2>
  );
}

export function H3({ children, className, ...props }: TypographyProps) {
  return (
    <h3
      {...props}
      className={cn(
        'scroll-m-20 text-xl sm:text-2xl font-semibold tracking-tight',
        className,
      )}
    >
      {children}{' '}
    </h3>
  );
}
export function H4({ children, className, ...props }: TypographyProps) {
  return (
    <h4
      {...props}
      className={cn(
        'scroll-m-20 text-lg sm:text-xl font-semibold tracking-tight',
        className,
      )}
    >
      {children}{' '}
    </h4>
  );
}

export function P({ children, className, ...props }: TypographyProps) {
  return (
    <p
      {...props}
      className={cn(
        'leading-7 text-base [&:not(:first-child)]:mt-6',
        className,
      )}
    >
      {children}
    </p>
  );
}

export function Blockquote({ children, className, ...props }: TypographyProps) {
  return (
    <blockquote
      {...props}
      className={cn('mt-6 border-l-2 pl-6 italic', className)}
    >
      {children}
    </blockquote>
  );
}

export function Lead({ children, className, ...props }: TypographyProps) {
  return (
    <p {...props} className={cn('text-xl text-muted-foreground', className)}>
      {children}
    </p>
  );
}

export function Large({ children, className, ...props }: TypographyProps) {
  return (
    <div {...props} className={cn('text-lg font-semibold', className)}>
      {children}
    </div>
  );
}

export function Small({ children, className, ...props }: TypographyProps) {
  return (
    <small
      {...props}
      className={cn('text-sm font-medium leading-none', className)}
    >
      {children}{' '}
    </small>
  );
}

export function Muted({ children, className, ...props }: TypographyProps) {
  return (
    <p {...props} className={cn('text-sm text-muted-foreground', className)}>
      {children}{' '}
    </p>
  );
}
