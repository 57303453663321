import { NavLink } from "./NavLink";

export function NavLinkList({ isOpen }: { isOpen: boolean }) {
  return (
    <>
      <div className='hidden md:flex md:flex-row '>
        <NavLink href='/#faq'>FAQ</NavLink>
        <NavLink href='/about'>About Us</NavLink>
        <NavLink href='/#how'>How to download</NavLink>
        <NavLink target='_blank' href='https://www.freepik.com/'>
          Freepik
        </NavLink>
      </div>

      <div
        className={`block md:hidden absolute top-0 left-0 right-0 z-50 w-full  bg-primary text-foreground transition-all overflow-hidden ${
          isOpen ? 'h-[100dvh]' : 'h-0'
        }`}
      >
        <div className='text-2xl font-medium mt-20 flex flex-col flex-nowrap gap-2 h-full transition'>
          <NavLink href='/#faq'>FAQ</NavLink>
          <NavLink href='/#about'>About Us</NavLink>
          <NavLink href='/#how' scroll={false}>
            How to download
          </NavLink>
          <NavLink target='_blank' href='https://www.freepik.com'>
            Freepik
          </NavLink>
        </div>
      </div>
    </>
  );
}
