"use client";
import Link from "next/link";
import { useState } from "react";
import { NavbarToggleButton } from "../NavbarToggleButton";

import { NavLinkList } from "./NavLinkList";
import { PolicyNavLinkList } from "./PolicyNavLinkList";

export function Navbar({ type = "default" }) {
  const [isOpen, setIsOpen] = useState(false);

  function handleClick() {
    setIsOpen((isOpen) => !isOpen);
  }

  return (
    <nav
      className={`justify-between flex bg-primary text-primary-foreground items-center flex-row md:gap-12 px-5 sm:px-10 md:px-16 lg:px-24 py-1`}
    >
      <header>
        <Link
          title="Freepik Downloader"
          href="/"
          className="text-2xl font-bold"
        >
          <span className="hidden sm:block">Freepik Downloader</span>
          <span className="block sm:hidden uppercase">FD</span>
        </Link>
      </header>
      {type === "default" ? (
        <NavLinkList isOpen={isOpen} />
      ) : (
        <PolicyNavLinkList isOpen={isOpen} />
      )}

      <NavbarToggleButton onToggle={handleClick} isOpen={isOpen} />
    </nav>
  );
}
